@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Roboto, sans-serif;
  }
}

@layer components {
  .app-layout {
    background: linear-gradient(
      theme(colors.brand.DEFAULT),
      theme(colors.brand.DEFAULT) 308px,
      theme(colors.gray.50) 308px
    );
  }
}

html {
  font-feature-settings: 'case' on, 'cpsp' on, 'frac' on, 'salt' on, 'tnum' on, 'ss04' on;
}
